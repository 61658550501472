.dashbordWrapper {
    display: flex;
    flex-direction: row;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

.header {
    width: 15%;
    height: 100%;
    padding: 12px;
    border-right: 1px solid rgba(128, 128, 128,0.3);
    overflow-y: scroll;
}

.main{
    width: 80%;
    height: 100vh;
    padding: 40px 20px;
    overflow: scroll;
}

#checkbox, #label_checkbox{
    display: none;
}

.dashboardTitle{
    margin-bottom: 20px;
    color: rgb(0, 115, 95);
}

.pTable{
    margin-bottom: 12px;
    padding: 12px;
    transition-duration: 2s;
    border-radius: 12px;
}

.pTable > a{
    padding: 12px;
    text-decoration: none;
    color: black;
}



.active{
    border-right: 5px solid rgb(0, 115, 95);
    border-radius: 20px;
}
::-webkit-scrollbar{
    color: rgb(0, 115, 95);
}

.list{
    margin-bottom: 20px;
    color: rgb(0, 115, 95);
}


@media  screen and (max-width : 800px) {
    
    
    .header {
        position: absolute;
        top: 0;
        left: -100%;
        bottom: 0;
        width: 80%;
        background-color: #ffffff;
        padding-top: 40px;
        transition: 0.5s;
    }

    .main{
        width: 100%;
    }
    #label_checkbox{
        display: inline;
        position: absolute;
        top: 5px;
        left: 12px;
        z-index: 100;
        padding: 4px;
        border: 1px solid rgb(0, 115, 95);
    }

    #checkbox:checked ~ div{
        left: 0;
    }
    
}

