.loginWrapper {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.titleLogin {
    color: rgb(0, 155, 95);
    margin-bottom: 20px;
    text-align: center;
}


.wrapperField{
    margin-top: 20px;
}

.pLabel{
    margin-bottom: 12px;
}

.inputLogin {
    width: 280px;
    height: 45px;
    border: 1px solid black;
    border-radius: 12px;
    padding: 0 8px;
    outline: none;
}



.submitLogin{
    width: 280px;
    height: 45px;
    border: none;
    background-color: rgb(0, 155, 95);
    border-radius: 12px;
    color: rgb(255, 255, 255);
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
}

.error {
    display: block;
    color: red;
    text-align: center;
}