.userCard, .cityCard{
    box-shadow: 1px 1px 2px gray;
    padding: 12px;
    border-radius: 12px;
    margin-bottom: 20px;
}

.userCard > p,h2,i {
    padding: 4px;
}

.pLink{
    margin-top: 20px;
    text-align: right;

}

.pLink > a {
    padding: 8px 12px;
    background-color: rgb(0, 115, 95);
    border-radius: 12px;
    text-decoration: none;
    color: white;
    font-weight: bold;
}



.newLink {
    padding: 12px 18px;
    text-decoration: none;
    color: white;
    background-color: rgb(0, 115, 95);
    border-radius: 8px;
    position: fixed;
    top: 5px;
    right: 2px;
}


@media screen and (max-width : 600px) {
    .pLink > a {
        font-weight: normal;
        padding: 6px;
    }
}