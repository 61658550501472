.adCard{
    display: flex;
    margin-bottom: 40px;
    box-shadow: 1px 1px 2px gray;
    padding: 12px;
    border-radius: 12px;
}

.divImg {
    margin-right: 34px;
}

.imgAd {
    width: 200px;
}
.adInfo{
    padding: 5px;
}



@media screen and (max-width : 800px) {
    .adCard{
        flex-direction: column;
    }

    .divImg {
        margin-right:0;
    }

    .imgAd {
        width: 100%;
    }
    .adInfo{
        padding: 8px 0;
    }
}
