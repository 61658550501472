.mindCard{
    box-shadow: 1px 1px 4px gray;
    padding: 12px;
    border-radius: 12px;
    margin-bottom: 20px;
}

.mindCard > h2,h4,p {
    padding: 8px 0;
}

.pRight{
    text-align: right;
}