.wrapperTableCard{
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    overflow: scroll;
}

.tableCard {
    margin-bottom: 30px;
    min-width: 210px;
    height: 210px;
    box-shadow: 1px 1px 2px gray;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-right: 20px;
    border-radius: 12px;
}

.tableTitle{
    margin-bottom: 10px;
    color: rgb(0,115,95);
}