.divForm{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.div{
    margin-bottom: 20px;
}

.div > h2{
    color: rgb(0,115,95);
}

.field{
    width: 250px;
    height: 40px;
    border-radius: 8px;
    padding: 0 8px;
    outline: none;
}

.div > input[type='submit']{
    background-color: rgb(0,115,95);
    color: white;
    border: none;
}